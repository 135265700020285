.topProduct{
     padding: 0!important;
    margin: 0 !important;
   //background-image: url("../../public/assets/img/slide/pexels-m-venter-1659437.jpg");
   background-image: linear-gradient(to left,#fff,#f5f5f5);
   background-size: cover;
    .card-group{
        
    }
    h2{
        color: black;
        font-size: 3em;
        font-weight: bolder;
    }
}

.products{
    padding-top: 2em !important;
    padding-bottom: 5em !important;
    margin: 0 !important;
    .item{
        cursor: pointer;
        transition-delay: 10ms;
        transition-duration: 200ms;
        transition-timing-function: ease-in;
        background-color: white;

        &:hover{
             box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
             transform: scale(1.01);
        }
    }
    img{
    width: 300px;
    height: 300px;
    }
}