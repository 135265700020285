.About{
     width: 103.5vw;
    background-image: url('./../../public/assets/aboutbg.svg');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    text-align: justify; 
}

.Mission{
    font-size: 17px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    text-align: justify;
}

.Vision{
    text-align: justify;
    width: 103.5vw;
    background-image: url('./../../public/assets/mission.svg');
    background-repeat: no-repeat;
    background-size: 100% 105%;
    text-align: justify; 
    font-size: 17px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
@media only screen and (max-width: 600px) {
  .About {
    background-size: 100% 130%;
  }
  .Vision {
    background-size: 100% 100%;
  }
}