.Widget{
    overflow: hidden;
    //height: 75vh;
    font-size: 16px;
    background-color: #f5f5f5;

    img{
       width: 300px;
       height: 300px;
    }
    .actionBtn{
        cursor: pointer;
        width: 50px;
        height: 50px;
        background-color: white;
        border-radius: 50px;
    }
}
.pay-btn{
    background-color: #25d366;
    color: white;
    font-weight: bold;
    border: none;
    border-radius: 15px;
    height: 40px;
    transition: .4s ease-in-out;
    &:hover{
        background-color: #128c7e;
    }
}